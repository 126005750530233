import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sanitation-reports--filters"
export default class extends Controller {
  static targets = ["customDateRangeFields"];

  connect() {
    this.toggleCustomDateRangeFields();
  }

  toggleCustomDateRangeFields() {
    const dateRangeSelect = document.getElementById("q_date_range");
    const isCustom = dateRangeSelect.value === "custom";
    const fromDate = document.getElementById("q_created_at_gteq");
    const toDate = document.getElementById("q_created_at_lteq");

    if (!isCustom) {
      // Clear date range fields if not custom
      fromDate.value = "";
      toDate.value = "";

      // Remove required attribute
      fromDate.required = false;
      toDate.required = false;
    } else {
      fromDate.required = true;
      toDate.required = true;
    }

    this.customDateRangeFieldsTarget.classList.toggle("hidden", !isCustom);
  }
}
