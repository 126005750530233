import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="table-sort"
export default class extends Controller {
  static targets = ["tbody"];

  connect() {
    this.currentColumn = null;
    this.ascending = true;
  }

  sort(event) {
    const header = event.currentTarget;
    const columnIndex = Array.from(header.parentElement.children).indexOf(
      header
    );
    // Toggle sort direction if clicking the same column
    if (this.currentColumn === columnIndex) {
      this.ascending = !this.ascending;
    } else {
      this.ascending = true;
      this.currentColumn = columnIndex;
    }

    // Update sort indicators
    this.updateSortIndicators(header);

    // Sort the rows
    const rows = Array.from(this.tbodyTarget.querySelectorAll("tr"));
    const sortedRows = rows.sort((rowA, rowB) => {
      const cellA = this.getCellValue(
        rowA.querySelector(
          `td:nth-child(${columnIndex + 1}), th:nth-child(${columnIndex + 1})`
        )
      );
      const cellB = this.getCellValue(
        rowB.querySelector(
          `td:nth-child(${columnIndex + 1}), th:nth-child(${columnIndex + 1})`
        )
      );
      return this.compare(cellA, cellB);
    });

    // Reorder the table
    this.tbodyTarget.innerHTML = "";
    sortedRows.forEach((row) => this.tbodyTarget.appendChild(row));
  }

  getCellValue(cell) {
    // First try to get the data-value attribute
    const value = cell.dataset.value;
    const valueType = cell.dataset.valueType;

    if (valueType === "number") {
      return parseFloat(value);
    } else if (valueType === "date") {
      return new Date(value);
    }

    // Return as string if neither number nor date
    return value;
  }

  compare(a, b) {
    if (a < b) return this.ascending ? -1 : 1;
    if (a > b) return this.ascending ? 1 : -1;
    return 0;
  }

  updateSortIndicators(currentHeader) {
    // Remove all existing sort indicators
    this.element.querySelectorAll("th i").forEach((icon) => {
      icon.className = "i-ph-arrows-down-up";
    });

    // Update current sort indicator
    const icon = currentHeader.querySelector("i");
    icon.className = this.ascending ? "i-ph-arrow-up" : "i-ph-arrow-down";
  }
}
