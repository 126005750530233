import { Controller } from "@hotwired/stimulus";
import {
  initializeSanitationRoute,
  getNextDatapointUrl,
} from "../../db/sanitationRoutes";

// Connects to data-controller="sanitation-forms--first-step"
export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
    "loadingSpinner",
    "nextIcon",
    "userSelect",
    "lotSelect",
  ];

  static values = {
    sanitationForm: Object,
    lots: Object,
  };

  connect() {}

  async submit(e) {
    e.preventDefault();

    // Prevent multiple submissions
    if (this.submitButtonTarget.disabled) return;

    try {
      // Disable submit button and show loading state
      this.submitButtonTarget.disabled = true;
      this.nextIconTarget.classList.add("hidden");
      this.loadingSpinnerTarget.classList.remove("hidden");

      const userId = this.userSelectTarget.value;
      const userName = this.userSelectTarget.selectedOptions[0].innerText;
      const lot = this.lotsValue[this.lotSelectTarget.value];

      const routeId = await initializeSanitationRoute({
        lot,
        userId,
        userName,
        sanitationForm: this.sanitationFormValue,
      });

      const nextStepUrl = await getNextDatapointUrl(routeId);
      window.location.href = nextStepUrl;
    } catch (error) {
      // Re-enable submit button and hide loading state if there's an error
      this.submitButtonTarget.disabled = false;
      this.nextIconTarget.classList.remove("hidden");
      this.loadingSpinnerTarget.classList.add("hidden");
      alert(error.message);
    }
  }
}
