import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="field-cycle--form"
export default class extends Controller {
  static targets = ["endDateValue"];
  static values = { fieldCycleDuration: Number };

  updateEndDate(event) {
    const startDate = new Date(event.target.value);
    const endDate = new Date(
      startDate.setDate(startDate.getDate() + this.fieldCycleDurationValue)
    );

    // Get the locale, fallback to 'en' if not available
    const locale = document.documentElement.lang || "en";

    console.log({ startDate, endDate, locale });

    // Format the date according to the locale settings
    try {
      this.endDateValueTarget.textContent = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(endDate);
    } catch (e) {
      // Fallback to a simple date format if the locale is invalid
      this.endDateValueTarget.textContent = endDate.toLocaleDateString();
    }
  }
}
