import { Controller } from "@hotwired/stimulus";
import { patch as railsPatch, post as railsPost } from "@rails/request.js";
import { checkOnlineStatus } from "../../onlineStatus.js";
import {
  deleteSanitationRoute,
  getActiveSanitationRoute,
  getSanitationRoute,
} from "../../db/sanitationRoutes.js";

import { getRouteDatapoints } from "../../db/routeDatapoints.js";

import { getRouteLocations } from "../../db/routeLocations.js";

// Connects to data-controller="sanitation-routes--upload-button"
export default class extends Controller {
  static targets = [
    "uploadButton",
    "loadingSpinner",
    "successMessage",
    "errorMessage",
  ];

  static values = {
    confirmUpload: Boolean,
  };

  async getRouteData() {
    let sanitationRoute;
    const routeIdElement = this.uploadButtonTarget.closest(
      "[data-sanitation-route-id]"
    );

    if (routeIdElement) {
      const sanitationRouteId = Number(
        routeIdElement.dataset.sanitationRouteId
      );
      sanitationRoute = await getSanitationRoute(sanitationRouteId);
    } else {
      sanitationRoute = await getActiveSanitationRoute();
    }

    if (!sanitationRoute) {
      throw new Error("No active sanitation route found");
    }

    const routeDatapoints = await getRouteDatapoints(sanitationRoute.id);
    const routeLocations = await getRouteLocations(sanitationRoute.id);

    return {
      sanitationRoute,
      routeDatapoints,
      routeLocations,
    };
  }

  async uploadRoute() {
    this.disableUploadButton();

    if ((await checkOnlineStatus()) === false) {
      alert(
        "Estas sin conexión a internet. Intenta de nuevo uno vez que tengas conexión."
      );
      this.enableUploadButton();
      return;
    }

    const { sanitationRoute, routeDatapoints, routeLocations } =
      await this.getRouteData();

    if (routeDatapoints.length === 0) {
      alert(
        "Esta ruta no tiene muestras. Por favor agrega muestras de datos antes de subir la ruta."
      );
      this.enableUploadButton();
      return;
    }

    if (this.confirmUploadValue) {
      if (
        !confirm(
          "¿Estás seguro que deseas subir la ruta de sanidad sin completarla?"
        )
      ) {
        this.enableUploadButton();
        return;
      }
    } else {
      if (!confirm("¿Estás seguro que deseas subir la ruta de sanidad?")) {
        this.enableUploadButton();
        return;
      }
    }

    this.showLoadingSpinner();

    const totalTreesInRoute =
      sanitationRoute.total_trees_in_route === Infinity
        ? sanitationRoute.current_tree_number - 1
        : sanitationRoute.total_trees_in_route;

    const response = await railsPost(
      `/farms/${sanitationRoute.farm_id}/upload_sanitation_route`,
      {
        body: {
          data: {
            total_trees_in_route: totalTreesInRoute,
            sanitation_route: JSON.stringify(sanitationRoute),
            route_datapoints: JSON.stringify(
              this.mapToServerShapeRouteDatapoints(routeDatapoints)
            ),
            route_locations: JSON.stringify(routeLocations || []),
          },
        },
      }
    );

    if (response.ok) {
      await deleteSanitationRoute(sanitationRoute.id);

      this.showSuccessMessage();
    } else {
      this.showErrorMessage();
    }

    this.hideLoadingSpinner();
    this.disableUploadButton();
  }

  mapToServerShapeRouteDatapoints(routeDatapoints) {
    return routeDatapoints.map((routeDatapoint) => {
      return Object.assign(routeDatapoint.data.route_datapoint, {
        custom_field_values_attributes:
          routeDatapoint.data.route_datapoint.custom_field_values_attributes?.filter(
            (customField) =>
              customField.value != null && customField.value !== ""
          ) || [],
        created_at: routeDatapoint.created_at,
        tree_number: routeDatapoint.tree_number,
      });
    });
  }

  showLoadingSpinner() {
    this.loadingSpinnerTarget.classList.remove("hidden");
  }

  hideLoadingSpinner() {
    this.loadingSpinnerTarget.classList.add("hidden");
  }

  showSuccessMessage() {
    this.successMessageTarget.classList.remove("hidden");
  }

  hideSuccessMessage() {
    this.successMessageTarget.classList.add("hidden");
  }

  showErrorMessage() {
    this.errorMessageTarget.classList.remove("hidden");
  }

  hideErrorMessage() {
    this.errorMessageTarget.classList.add("hidden");
  }

  disableUploadButton() {
    this.uploadButtonTarget.setAttribute("disabled", true);
  }

  enableUploadButton() {
    this.uploadButtonTarget.removeAttribute("disabled");
  }
}
